<template>
  <div>
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('users.create')"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="!isVisibleDialog"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="user.name"
                    dense
                    :label="$t('name_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="user.email"
                    dense
                    :label="$t('email_*')"
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>

                <v-col
                  v-if="authUser.isAdmin"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="user.user_type"
                    dense
                    :items="userTypeList"
                    item-value="id"
                    item-text="name"
                    :label="$t('user_type_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  v-if="
                    !isSystemUserType &&
                      authUser.isAdmin &&
                      user.user_type == 'oem'
                  "
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="user.client_id"
                    dense
                    :loading="isLoadingClient"
                    :disabled="isSystemUserType || isLoadingClient"
                    :items="user.user_type ? clientsList : []"
                    item-text="name"
                    item-value="id"
                    :label="$t('select_tenant_*')"
                    :rules="!isSystemUserType ? [rules.required] : null"
                  />
                </v-col>

                <v-col
                  v-if="
                    !isSystemUserType &&
                      authUser.isAdmin &&
                      user.user_type == 'tenant'
                  "
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="user.tenant_id"
                    dense
                    :loading="isLoadingClient"
                    :disabled="isSystemUserType || isLoadingClient"
                    :items="user.user_type ? clientsList : []"
                    item-text="name"
                    item-value="id"
                    :label="$t('select_tenant_*')"
                    :rules="!isSystemUserType ? [rules.required] : null"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="user.password"
                    dense
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    autocomplete="false"
                    :label="$t('password_*')"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[rules.required, rules.min_8]"
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="user.role"
                    dense
                    clearable
                    :items="
                      authUser.isAdmin
                        ? user.user_type
                          ? listRoles
                          : []
                        : listRoles
                    "
                    item-text="name"
                    item-value="name"
                    :label="$t('role_*')"
                    :rules="[rules.multi_select_required]"
                    multiple
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="user.is_active"
                    dense
                    :items="statusLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('status_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  v-if="isUserRequiredLocation"
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="user.location_id"
                    dense
                    :disabled="!isUserRequiredLocation"
                    :items="locationsList"
                    item-text="name"
                    item-value="id"
                    :label="$t('select_location_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isLoadingSave"
              @click="saveItem(user)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isVisibleDialog: false,
      isLoadingSave: false,
      showPassword: false,
      user: {},
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
      userTypeList: [
        {
          id: "orko",
          name: "ORKO",
        },
        {
          id: "tenant",
          name: "Tenant",
        },
        // {
        //   id: "cpo",
        //   name: "CPO",
        // },
      ],
    };
  },

  computed: {
    ...mapGetters({
      listRoles: "roles/listRoles",
      clientsList: "meta/clientsList",
      isLoadingClient: "meta/isLoadingClient",
      locationsList: "locations/locationsList",
      authUser: "getUser",
    }),

    isSystemUserType() {
      return this.user.user_type == "orko";
    },
    isUserRequiredLocation() {
      return this.user.role
        ? this.user.role.includes("Cpo-Manager") ||
            this.user.role.includes("Cpo-Staff")
        : false;
    },
  },
  watch: {
    "user.user_type": {
      handler: async function (value) {
        if (value) {
          // if (value) {
          //   this.$store.dispatch("roles/list", { type: value });
          // } else {
          //   this.$store.commit("roles/SET_ROLES", []);
          // }
          await this.$store.dispatch("meta/getClients", value);
          if (value == "orko") {
            await this.$store.dispatch("roles/list", { type: value });
          } else if (
            value == "oem" &&
            this.user.client_id !== undefined &&
            this.user.client_id !== null
          ) {
            await this.$store.dispatch("roles/list", {
              tenant_id: this.user.client_id,
            });
          } else if (
            value == "tenant" &&
            this.user.tenant_id !== undefined &&
            this.user.tenant_id !== null
          ) {
            await this.$store.dispatch("roles/list", {
              tenant_id: this.user.tenant_id,
            });
          } else {
            this.$store.commit("roles/SET_ROLES", []);
          }
        }
      },
    },
    "user.role": {
      handler: async function () {
        if (this.isUserRequiredLocation) {
          await this.$store.dispatch("locations/list", {
            cpo_client_id: this.user.client_id,
          });
        }
      },
    },
    "user.client_id": {
      handler: async function () {
        await this.$store.dispatch("roles/list", {
          tenant_id: this.user.client_id,
        });
        if (this.isUserRequiredLocation) {
          await this.$store.dispatch("locations/list", {
            cpo_client_id: this.user.client_id,
          });
        }
      },
    },
    "user.tenant_id": {
      handler: async function () {
        await this.$store.dispatch("roles/list", {
          tenant_id: this.user.tenant_id,
        });
      },
    },
    isVisibleDialog: {
      async handler(newValue) {
        if (newValue) {
          if (
            this.authUser.user_type !== "oem" &&
            this.authUser.user_type !== "tenant"
          ) {
            this.$store.commit("roles/SET_ROLES", []);
          }
        }
      },
      immediate: true,
    },
  },
  async created() {
    if (!this.authUser.isAdmin) {
      await this.$store.dispatch("roles/list", {
        type: this.authUser.user_type,
      });
    }
  },

  methods: {
    closeForm() {
      // this.$refs.form.reset();
      this.user = {};
      this.$refs.form.resetValidation();
      this.isVisibleDialog = false;
      this.$emit("change");
    },
    async saveItem(user) {
      let params = { user, filter: { ...this.filterOptions } };

      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;

        await this.$store
          .dispatch("users/add", params)
          .then(() => {
            // this.$store.dispatch("users/list", { page: 1, itemsPerPage: 20 });
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.isLoadingSave = false;
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
  },
};
</script>
