var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.$admin.can('users.create'))?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){!_vm.isVisibleDialog}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("add_new"))+" ")]):_vm._e()]}}]),model:{value:(_vm.isVisibleDialog),callback:function ($$v) {_vm.isVisibleDialog=$$v},expression:"isVisibleDialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h3"},[_vm._v(_vm._s(_vm.$t("add_new")))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('name_*'),"rules":[_vm.rules.required]},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('email_*'),"rules":[_vm.rules.required, _vm.rules.email]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),(_vm.authUser.isAdmin)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"dense":"","items":_vm.userTypeList,"item-value":"id","item-text":"name","label":_vm.$t('user_type_*'),"rules":[_vm.rules.required]},model:{value:(_vm.user.user_type),callback:function ($$v) {_vm.$set(_vm.user, "user_type", $$v)},expression:"user.user_type"}})],1):_vm._e(),(
                  !_vm.isSystemUserType &&
                    _vm.authUser.isAdmin &&
                    _vm.user.user_type == 'oem'
                )?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"dense":"","loading":_vm.isLoadingClient,"disabled":_vm.isSystemUserType || _vm.isLoadingClient,"items":_vm.user.user_type ? _vm.clientsList : [],"item-text":"name","item-value":"id","label":_vm.$t('select_tenant_*'),"rules":!_vm.isSystemUserType ? [_vm.rules.required] : null},model:{value:(_vm.user.client_id),callback:function ($$v) {_vm.$set(_vm.user, "client_id", $$v)},expression:"user.client_id"}})],1):_vm._e(),(
                  !_vm.isSystemUserType &&
                    _vm.authUser.isAdmin &&
                    _vm.user.user_type == 'tenant'
                )?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"dense":"","loading":_vm.isLoadingClient,"disabled":_vm.isSystemUserType || _vm.isLoadingClient,"items":_vm.user.user_type ? _vm.clientsList : [],"item-text":"name","item-value":"id","label":_vm.$t('select_tenant_*'),"rules":!_vm.isSystemUserType ? [_vm.rules.required] : null},model:{value:(_vm.user.tenant_id),callback:function ($$v) {_vm.$set(_vm.user, "tenant_id", $$v)},expression:"user.tenant_id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"autocomplete":"false","label":_vm.$t('password_*'),"type":_vm.showPassword ? 'text' : 'password',"rules":[_vm.rules.required, _vm.rules.min_8]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"dense":"","clearable":"","items":_vm.authUser.isAdmin
                      ? _vm.user.user_type
                        ? _vm.listRoles
                        : []
                      : _vm.listRoles,"item-text":"name","item-value":"name","label":_vm.$t('role_*'),"rules":[_vm.rules.multi_select_required],"multiple":""},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"dense":"","items":_vm.statusLists,"item-value":"id","item-text":"name","label":_vm.$t('status_*'),"rules":[_vm.rules.required]},model:{value:(_vm.user.is_active),callback:function ($$v) {_vm.$set(_vm.user, "is_active", $$v)},expression:"user.is_active"}})],1),(_vm.isUserRequiredLocation)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"dense":"","disabled":!_vm.isUserRequiredLocation,"items":_vm.locationsList,"item-text":"name","item-value":"id","label":_vm.$t('select_location_*'),"rules":[_vm.rules.required]},model:{value:(_vm.user.location_id),callback:function ($$v) {_vm.$set(_vm.user, "location_id", $$v)},expression:"user.location_id"}})],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('small',[_vm._v(_vm._s(_vm.$t("indicates_required_field")))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.closeForm()}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.isLoadingSave},on:{"click":function($event){return _vm.saveItem(_vm.user)}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }